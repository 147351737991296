/* eslint-disable no-param-reassign */
import React from "react";
import PropsTypes from "prop-types";
import Chat from "../../chat/chat";
import ParticipantsTab from "./ParticipantsTab";

function ChatParticipants({
  visibleTab,
  games,
  chats,
  isExpanded,
  handleTabs,
}) {
  return (
    <div className="chat-wrapper viewer-wrapper waiting-chat-wrapper">
      <div className="tab-card-head chat-participant-head">
        <div
          role="presentation"
          className={`chat-participant-tab ${
            visibleTab?.isParticipants ? "" : "active"
          }`}
          onClick={() => handleTabs("isChat", visibleTab?.isChat)}
        >
          {/* <ChatIcon /> */}
          <h4>Chat</h4>
        </div>
        <div
          role="presentation"
          className={`chat-participant-tab ${
            visibleTab?.isParticipants ? "active" : ""
          }`}
          onClick={() =>
            handleTabs("isParticipants", visibleTab?.isParticipants)}
        >
          {/* <CallIcon /> */}
          <h4>Participants</h4>
        </div>
      </div>
      {visibleTab?.isParticipants ? (
        <ParticipantsTab games={games} isInGameChat />
      ) : (
        <Chat chats={chats} isExpanded={isExpanded} isInGameChat />
      )}
    </div>
  );
}

ChatParticipants.defaultProps = {
  isExpanded: false,
};

ChatParticipants.propTypes = {
  games: PropsTypes.array.isRequired,
  visibleTab: PropsTypes.object.isRequired,
  chats: PropsTypes.array.isRequired,
  handleTabs: PropsTypes.func.isRequired,
  isExpanded: PropsTypes.bool,
};
export default ChatParticipants;
