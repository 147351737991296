import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import PropsTypes from "prop-types";
import MultiTableGameView from "../../multiTable/multiTableGameView";
import MultiTableIcon from "../../../IconComponents/MultiTableIcon";

function MultiTableTab({ allGames }) {
  const settings = {
    dots: false,
    infinite: false,
    speed: 800,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    className: "myCustomCarousel",
  };

  return (
    <div className="chat-wrapper viewer-wrapper multi-table-viewer-wrapper">
      <div className="tab-card-head multi-table-head">
        <MultiTableIcon />
        <h4>
          Multi-Table
          {/* <span>{viewersCounts || 0}</span> */}
        </h4>
      </div>
      <div className="multi-table-card-body">
        <Slider {...settings}>
          {allGames?.map((el) => (
            <div key={el?.id}>
              <MultiTableGameView gameId={el?.id} />
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
}

MultiTableTab.propTypes = {
  allGames: PropsTypes.array.isRequired,
};

export default MultiTableTab;
