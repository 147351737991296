/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/media-has-caption */
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useParams } from "react-router-dom";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import PropsTypes from "prop-types";
// eslint-disable-next-line import/no-extraneous-dependencies
import ReactLinkify from "react-linkify";
import ChatIcon from "../../IconComponents/ChatIcon";
// import SendIcon from "../../IconComponents/SendIcon";
import UserContext from "../../context/UserContext";
import TimeFormat from "../../utils/chatTimeFormat";
import EmojiIcon from "../../IconComponents/EmojiIcon";
import ProfileModal from "../profile/profileModal";
import cross from "../../assets/images/profile/cross.svg";
import ChatBtnIcon from "../../IconComponents/ChatBtnIcon";
import ChatEmoji from "../chatEmoji/chatEmoji";
import socket from "../../config/socket";
import { chatInstance } from "../../config/axios";
import ChatCamIcon from "../../IconComponents/ChatCamIcon";
import CamModal from "./camModal";
import UploadImageModal from "./uploadImageModal";
import WinnerDefaultImage from "../../assets/images/winnerDefaultImg.png";
import CustomLink from "../../utils/customLink";
import ViewImageModal from "./viewImageModal";
import "./chat.css";
import EyeIcon from "../../IconComponents/EyeIcon";
import getRandomColor from "../../utils/generateRandomColor";
import { debounce } from "../../utils";
import SendIconV2 from "../../IconComponents/SendIconV2";

const chatColor = [];
let stream;

function Chat({ chats, isExpanded, isInGameChat }) {
  const { gameEvent, allGamesData, user, userCamera } = useContext(UserContext);
  const { gameId } = useParams();
  const [tablesCount, setTablesCount] = useState(null);
  const gameData = allGamesData[gameId];
  const scrollableRef = useRef(null);
  const [showProfile, setShowProfile] = useState(false);
  const [message, setMessage] = useState("");
  const [chatUserId, setChatUserId] = useState("");
  const [showEmoji, setShowEmoji] = useState("");
  const [showCam, setShowCam] = useState(false);
  const [reactedEmoji, setReactedEmoji] = useState({
    id: "",
    emoji: "",
    count: 0,
  });

  // Image States
  const [showCamModal, setShowCamModal] = useState(false);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [sendImgLoader, setSendImgLoader] = useState(false);
  const intialFileData = {
    file: "",
    caption: "",
  };
  const [file, setFile] = useState(intialFileData);
  const [preview, setPreview] = useState("");
  const [allCameraOptions, setAllCameraOptions] = useState([]);
  const [selectedCamera, setSelectedCamera] = useState(userCamera?.value);
  const showCamOption = useRef(null);
  const [cameraError, setCameraError] = useState(false);
  const [disableReaction, setDisableReaction] = useState(false);
  const [uploadError, setUploadError] = useState("");
  const [viewImage, setViewImage] = useState(false);
  const [chatImageUrl, setChatImageUrl] = useState("");
  const maxLength = 200;

  const handleClose = () => {
    setShowProfile(false);
  };

  const handleShowChatProfile = (userId) => {
    setChatUserId(userId);
    setShowProfile(true);
  };

  const handleChangeMessage = (e, name) => {
    if (name === "emoji") {
      //  debounce(setMessage, e, 200);
      setMessage((old) => old + e);
    } else {
      const { value } = e.target;

      if (value.length <= maxLength) {
        debounce(setMessage, value, 200);
        setMessage(value);
      } else {
        setMessage(value.substring(0, 200));
        const inputBox = document.getElementById("chatMessage");
        if (inputBox) {
          inputBox.placeholder = "Chat limit is 200 characters";
          setTimeout(() => {
            inputBox.placeholder = "Send a message";
          }, 5000);
        }
      }
    }
  };

  const handleSendNewMessage = (e) => {
    e.preventDefault();
    if (message.length === 0) {
      return;
    }
    socket.emit("newMessage", {
      userId: user.id,
      gameId,
      gameEventId: gameEvent?.id,
      message,
    });
    setMessage("");
  };

  useEffect(() => {
    if (gameEvent?.games?.length > 0) {
      const tablesCountObj = {};
      gameEvent.games.forEach((game, i) => {
        tablesCountObj[String(game.id)] = i + 1;
      });
      setTablesCount({ ...tablesCountObj });
    }
  }, [gameEvent?.games?.length]);

  const scrollToBottom = useCallback(() => {
    scrollableRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [scrollableRef]);

  useEffect(() => {
    scrollToBottom();
  }, [
    chats?.length,
    chats[chats.length - 1]?.reactions?.length,
    scrollableRef,
    isExpanded,
  ]);

  const getAllPlayers = gameData?.players?.concat(
    gameData?.viewers,
    gameData?.spectators
  );

  useEffect(() => {
    getAllPlayers?.forEach((element) => {
      chatColor.push({ id: element.id, color: getRandomColor() });
    });
  }, [gameData?.players, gameData?.viewers]);

  const handleReaction = (e, type, cId) => {
    if (!cId) return;
    socket.emit("reactions", {
      gameEventId: gameEvent?.id,
      chatId: cId,
      userId: user?.id,
      role: user?.role,
      emoji: e,
    });
    setReactedEmoji({ ...reactedEmoji });
    setDisableReaction(true);
    setTimeout(() => {
      setDisableReaction(false);
    }, 800);
  };

  // Work of taking a picture

  const handleCamerOptions = (e) => {
    const { value } = e;
    setSelectedCamera(value);
  };

  const getCameraOptions = async () => {
    try {
      if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
        const devices = await navigator.mediaDevices.enumerateDevices();
        const cameraOptions = devices.filter(
          (device) => device.kind === "videoinput"
        );

        setAllCameraOptions(
          cameraOptions.map((camera) => ({
            label:
              camera.label || `Camera ${cameraOptions.indexOf(camera) + 1}`,
            value: camera.deviceId,
          }))
        );
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error("Error fetching camera options:", error);
    }
  };

  const handleCamModal = () => {
    if (!showCamModal) getCameraOptions();
    setShowCamModal(true);
    setShowCam(false);
    setPreview("");
    setFile(intialFileData);
  };

  const handleUploadModal = () => {
    setShowUploadModal(true);
    setShowCam(false);
    setPreview("");
    setFile(intialFileData);
  };

  const handleImageChange = (e) => {
    if (!e[0]) return;
    setUploadError("");
    const imageUrl = URL.createObjectURL(e[0]);
    setPreview(imageUrl);
    setFile({ ...file, file: e[0] });
  };

  const handleCaption = (e) => {
    setFile({ ...file, caption: e });
  };

  const handleSendMessageWithImage = async (e) => {
    e.preventDefault();
    setSendImgLoader(true);
    try {
      if (!file.file) return;
      const formData = new FormData();
      formData.append("file", file.file);
      formData.append("gameEventId", gameEvent?.id);
      formData.append("gameId", gameId);
      formData.append("caption", file.caption);
      formData.append("type", file.file.type);
      const response = await chatInstance().post("/uploadChatImage", formData);
      if (response) {
        setFile({ ...intialFileData });
        setShowCamModal(false);
        setShowUploadModal(false);
        setShowCam(false);
        setSendImgLoader(false);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log({ error });
      setSendImgLoader(false);
    }
  };

  const handleSubmit = (e) => {
    if (!file.file) {
      handleSendNewMessage(e);
    }
    // handleSendMessageWithImage(e);
  };

  function dataURItoBlob(dataURI) {
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    const byteString = atob(dataURI.split(",")[1]);

    // separate out the mime component
    const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

    // write the bytes of the string to an ArrayBuffer
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i += 1) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  }

  const generateRandomFileName = () => {
    const timestamp = new Date().getTime();
    const randomString = Math.random().toString(36).substring(2, 8);
    return `${timestamp}_${randomString}.jpg`;
  };

  const takePicture = () => {
    const canvas = document.getElementById("canvas");
    const video = document.getElementById("chatVideo");
    if (canvas && video) {
      const context = canvas.getContext("2d");
      context.drawImage(video, 0, 0, canvas.clientWidth, canvas.clientHeight);
      const data = canvas.toDataURL("image/png");
      const blobData = dataURItoBlob(data);
      const fileName = generateRandomFileName();
      const resultFile = new File([blobData], fileName, {
        type: "image/jpeg",
        lastModified: new Date(),
      });
      const imageUrl = URL.createObjectURL(resultFile);
      setPreview(imageUrl);
      setFile({ ...file, file: resultFile });
    }
  };

  const clearPhoto = () => {
    setFile({ ...file, file: "" });
    setPreview("");
  };

  const handleVideoClick = (e) => {
    if (e.target.paused) e.target.play();
    else e.target.pause();
  };

  useEffect(() => {
    const video = document.getElementById("chatVideo");

    const handleCamStream = async () => {
      try {
        const mediaObject = {
          video: true,
          audio: false,
        };

        if (selectedCamera) {
          mediaObject.video = {
            deviceId: selectedCamera,
            facingMode: "environment",
            aspectRatio: 16 / 9,
          };
        }

        stream = await navigator.mediaDevices.getUserMedia(mediaObject);

        if (stream && video) {
          video.srcObject = stream;
          video.play();
        }
      } catch (err) {
        setCameraError(true);
        // eslint-disable-next-line no-console
        console.error(`An error occurred: ${err}`);
      }
    };

    if (showCamModal && !file?.file) {
      handleCamStream();
    }

    if (!showCamModal && video && video.srcObject) {
      const tracks = video.srcObject.getTracks();
      tracks.forEach((track) => track.stop());
    }

    return () => {
      if (video && video.srcObject) {
        const tracks = video.srcObject.getTracks();
        tracks.forEach((track) => track.stop());
      }
    };
  }, [showCamModal, file, selectedCamera]);

  const handleViewCards = (hand) => {
    const winnerCard = hand?.winnerHand?.filter((el) =>
      hand?.winnerCards?.includes(el)
    );
    const winnerHandCards = hand?.winnerHand?.filter(
      (el) => !hand?.winnerCards?.includes(el)
    );
    return (
      <>
        <div
          className={`hand-history-card-wrapper ${
            winnerCard?.length > 0 ? "highlighted-winner-cards" : ""
          }`}
        >
          {winnerCard?.map((card, i) => (
            <img
              src={`/main-view-cards/${card}.svg`}
              alt={card}
              // eslint-disable-next-line react/no-array-index-key
              key={i + 1}
            />
          ))}
        </div>
        {winnerHandCards?.map((card, i) => (
          <img
            src={`/main-view-cards/${card}.svg`}
            alt={card}
            // eslint-disable-next-line react/no-array-index-key
            key={i + 2}
          />
        ))}
      </>
    );
  };

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setShowCam(false);
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  useOutsideAlerter(showCamOption);

  function deduplicateReactions(reactions) {
    const uniqueReactions = {};

    // eslint-disable-next-line no-restricted-syntax
    for (const reaction of reactions) {
      const { emoji } = reaction;

      if (uniqueReactions[emoji]) {
        // eslint-disable-next-line no-plusplus
        uniqueReactions[emoji].count++;
        uniqueReactions[emoji].userIds = Array.from(
          new Set([...uniqueReactions[emoji].userIds, ...reaction.userIds])
        );
      } else {
        uniqueReactions[emoji] = { ...reaction };
      }
    }

    return Object.values(uniqueReactions);
  }

  const linkComp = (href, text, key) => (
    <CustomLink href={href} key={key}>
      {text}
    </CustomLink>
  );

  const handleShowImage = (url) => {
    setChatImageUrl(url);
    setViewImage(true);
  };

  const handleViewClose = () => {
    setViewImage(false);
    setChatImageUrl("");
  };

  const handleCamClose = () => {
    if (stream) {
      stream.getTracks().forEach((track) => track.stop());
    }
    setShowCamModal(false);
  };

  const isSpectator = (specUser) =>
    (gameEvent?.games?.some((el) =>
      el?.spectators?.some((pl) => pl.id === specUser)
    ) ? (
      <span className="spec-eye-icon" title="Spectator">
        <EyeIcon />
      </span>
      ) : null);

  const renderTableNumbered = (tableNumber) => {
    if (tableNumber != null) {
      return (
        <p>
          Table
          <span className="TableNumber">
            #
            {tableNumber}
          </span>
        </p>
      );
    }
  };
  return (
    <div className="chat-wrapper waiting-chat-wrapper">
      {!isInGameChat && (
        <div className="tab-card-head waiting-tab-card-head">
          <ChatIcon />
          <h4>Chat</h4>
        </div>
      )}
      <div className={`chat-content-wrapper ${isInGameChat ? "participants-content-chat-wrapper" : "waiting-content-chat-wrapper"}`}>
        <div className="tab-card-body">
          {chats?.map((chat, chatIndex) => (
            <React.Fragment key={chat?.id || chatIndex + getRandomColor()}>
              {chat?.type !== "winnerHands" && chat?.type !== "tableWinner" ? (
                <div className="tab-card-element tab-chat-element waiting-tab-chat-element">
                  {user?.id !== chat?.userId?.id && (
                    <img
                      className="chat-avatar"
                      onClick={() => handleShowChatProfile(chat.userId)}
                      src={chat?.userId?.avatar}
                      onError={({ currentTarget }) => {
                        // eslint-disable-next-line no-param-reassign
                        currentTarget.onerror = null; // prevents looping
                        // eslint-disable-next-line no-param-reassign
                        currentTarget.src = "https://cloud-poker-night-worker.cloud-poker-night-dev.workers.dev/cpnDefaultUser.png";
                      }}
                      alt={chat?.firstName}
                      role="presentation"
                    />
                  )}

                  <div
                    className={`${
                      user?.id !== chat?.userId?.id
                        ? "chat-line-wrapper"
                        : "chat-line-wrapper-owner"
                    }`}
                  >
                    <div className="chat-text-wrapper">
                      <div className="chat-detail-wrapper">
                        <p
                          className={`chat-user-name ${
                            user?.id === chat?.userId?.id && "text-light"
                          }`}
                          onClick={() => handleShowChatProfile(chat.userId)}
                          style={{
                            color: `${
                              chatColor?.find((el) => el?.id === chat?.userId?.id)
                                ?.color
                            }`,
                          }}
                          role="presentation"
                        >
                          {user?.id === chat?.userId?.id
                            ? "You"
                            : chat?.userId?.firstName}
                        </p>
                        {tablesCount ? (
                          <span
                            className={`${
                              user?.id === chat?.userId?.id && "my-name"
                            }`}
                          >
                            {`Table #${tablesCount[chat?.gameId]}`}
                          </span>
                        ) : null}
                        {isSpectator(chat?.userId?.id)}
                      </div>
                      <p className="chat-message">
                        <ReactLinkify
                          componentDecorator={(href, text, key) =>
                            linkComp(href, text, key)}
                        >
                          {chat?.message}
                        </ReactLinkify>
                      </p>

                      {chat?.type?.includes("image") ? (
                        <img
                          className="chat-media-file"
                          src={chat.imageUrl}
                          alt="Not Found"
                          onClick={() => handleShowImage(chat.imageUrl)}
                          role="presentation"
                        />
                      ) : null}
                      {chat?.type?.includes("video") ? (
                        <video
                          className="chat-media-file"
                          src={chat.imageUrl}
                          onClick={handleVideoClick}
                          playsInline
                          autoPlay
                          muted
                          controls
                        />
                      ) : null}

                      <div className="reacted-emoji-wrapper">
                        {deduplicateReactions(chat?.reactions)?.map(
                          (eleme, reactionIndex) => (
                            <div
                              className="reacted-emoji"
                              role="presentation"
                              onClick={() =>
                                handleReaction(eleme?.emoji, "emoji", chat?.id)}
                              style={{
                                pointerEvents: `${
                                  disableReaction ? "none" : "unset"
                                }`,
                              }}
                              key={reactionIndex + 3}
                            >
                              <span role="presentation" aria-label="emoji">
                                {eleme?.emoji}
                              </span>
                              {eleme?.count >= 1 ? (
                                <p>{eleme?.userIds?.length}</p>
                              ) : null}
                              <div className="reaction-info-wrapper">
                                <div className="reacting-username">
                                  {eleme?.userIds?.map((el) => (
                                    <p key={el?.id}>{el?.firstName}</p>
                                  ))}
                                </div>
                                <p className="reaction-info-text">
                                  Reacted with:
                                  {" "}
                                  <span>{eleme?.emoji}</span>
                                </p>
                              </div>
                            </div>
                          )
                        )}
                      </div>
                    </div>
                    <div className="chat-right-wrapper">
                      <span
                        className={`${
                          user?.id !== chat?.userId?.id
                            ? "chat-time"
                            : "chat-time-shadow"
                        }`}
                      >
                        {TimeFormat(chat?.createdAt)}
                      </span>
                      <Button
                        className={`chat-react-btn ${
                          showEmoji === chat?.id ? "active" : ""
                        }`}
                        onClick={() => setShowEmoji(chat?.id)}
                      >
                        <EmojiIcon />
                      </Button>
                    </div>
                    <div
                      className={`chat-reaction-emoji ${
                        showEmoji === chat?.id ? "show" : ""
                      }`}
                    >
                      <ChatEmoji
                        name="reaction"
                        handleReaction={handleReaction}
                        setShowEmoji={setShowEmoji}
                        chatId={chat?.id}
                      />
                    </div>
                  </div>
                </div>
              ) : chat?.hands?.length > 0 ? (
                // eslint-disable-next-line react/jsx-no-useless-fragment
                <>
                  {chat?.hands?.map((hand, index) => (
                    <div
                      className="tab-card-element hand-chat-elem"
                      // eslint-disable-next-line react/no-array-index-key
                      key={hand?.id || index + getRandomColor()}
                    >
                      <div className="hand-chat-elem-wrapper">
                        <div className="hand-chat-wrapper">
                          <div className="hand-history-head">
                            <div className="hand-history-head-left">
                              <div className="hand-history-profile-wrapper">
                                <img
                                  src={hand?.avatar}
                                  onError={({ currentTarget }) => {
                                    // eslint-disable-next-line no-param-reassign
                                    currentTarget.onerror = null; // prevents looping
                                    // eslint-disable-next-line no-param-reassign
                                    currentTarget.src = "https://cloud-poker-night-worker.cloud-poker-night-dev.workers.dev/cpnDefaultUser.png";
                                  }}
                                  alt={hand?.name}
                                />
                                <div className="hand-winner-name">
                                  <p>{hand?.name}</p>
                                  <p className="hand-name">
                                    {hand?.handName
                                      ? hand?.handName
                                      : "All-Folded"}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="hand-history-head-right">
                              <div className="hand-pot-details">
                                <p>Pot:</p>
                                <span>
                                  $
                                  {hand?.winningAmount}
                                </span>
                              </div>
                            </div>
                          </div>

                          <div className="hand-history-card-wrapper">
                            {renderTableNumbered(chat?.tableNumber)}
                            <div className="CardJustify">
                              {handleViewCards(hand)}
                            </div>
                          </div>
                        </div>
                        <div className="hand-reacted-emoji-wrapper">
                          <div className="reacted-emoji-wrapper">
                            {chat?.reactions?.map((eleme, i) => (
                              <div
                                className="reacted-emoji"
                                role="presentation"
                                onClick={() =>
                                  handleReaction(
                                    eleme?.emoji,
                                    "emoji",
                                    chat?.id
                                  )}
                                style={{
                                  pointerEvents: `${
                                    disableReaction ? "none" : "unset"
                                  }`,
                                }}
                                key={i}
                              >
                                <span role="presentation" aria-label="emoji">
                                  {eleme?.emoji}
                                </span>
                                {eleme?.count >= 1 ? (
                                  <p>{eleme?.userIds?.length}</p>
                                ) : null}
                                <div className="reaction-info-wrapper">
                                  <div className="reacting-username">
                                    {eleme?.userIds?.map((el) => (
                                      <p key={el?.id}>{el?.firstName}</p>
                                    ))}
                                  </div>
                                  <p className="reaction-info-text">
                                    Reacted with:
                                    {" "}
                                    <span>{eleme?.emoji}</span>
                                  </p>
                                </div>
                              </div>
                            ))}
                          </div>
                          <Button
                            className={`chat-react-btn ${
                              showEmoji === chat?.id ? "active" : ""
                            }`}
                            onClick={() => setShowEmoji(chat?.id)}
                          >
                            <EmojiIcon />
                          </Button>
                          <div
                            className={`chat-reaction-emoji ${
                              showEmoji === chat?.id ? "show" : ""
                            }`}
                          >
                            <ChatEmoji
                              name="reaction"
                              handleReaction={handleReaction}
                              setShowEmoji={setShowEmoji}
                              chatId={chat?.id}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </>
              ) : chat?.type === "tableWinner" ? (
                <div className="tab-card-element hand-chat-elem">
                  <div className="hand-chat-elem-wrapper">
                    <div className="hand-chat-wrapper table-last-winner">
                      <div className="hand-history-profile-wrapper">
                        <img
                          src={
                            chat?.userId?.avatar?.includes("cpnDefaultUser")
                            || chat?.userId?.avatar?.includes("user")
                              ? WinnerDefaultImage
                              : chat?.userId?.avatar
                          }
                          onError={({ currentTarget }) => {
                            // eslint-disable-next-line no-param-reassign
                            currentTarget.onerror = null; // prevents looping
                            // eslint-disable-next-line no-param-reassign
                            currentTarget.src = { WinnerDefaultImage };
                          }}
                          alt={chat?.userId?.name}
                        />
                      </div>
                      <div className="hand-winner-name">
                        {tablesCount ? (
                          <p>{`Table #${tablesCount[chat?.gameId]} Winner`}</p>
                        ) : null}
                        <p className="hand-name">
                          {chat?.userId?.firstName}
                          {" "}
                          {chat?.userId?.lastName}
                        </p>
                      </div>
                    </div>
                    <div className="hand-reacted-emoji-wrapper">
                      <div className="reacted-emoji-wrapper">
                        {chat?.reactions?.map((eleme, i) => (
                          <div
                            className="reacted-emoji"
                            role="presentation"
                            onClick={() =>
                              handleReaction(eleme?.emoji, "emoji", chat?.id)}
                            style={{
                              pointerEvents: `${
                                disableReaction ? "none" : "unset"
                              }`,
                            }}
                            key={`${chat?.id}rea${i}ct`}
                          >
                            <span role="presentation" aria-label="emoji">
                              {eleme?.emoji}
                            </span>
                            {eleme?.count >= 1 ? (
                              <p>{eleme?.userIds?.length}</p>
                            ) : null}
                            <div className="reaction-info-wrapper">
                              <div className="reacting-username">
                                {eleme?.userIds?.map((el) => (
                                  <p key={el?.id}>{el?.firstName}</p>
                                ))}
                              </div>
                              <p className="reaction-info-text">
                                Reacted with:
                                {" "}
                                <span>{eleme?.emoji}</span>
                              </p>
                            </div>
                          </div>
                        ))}
                      </div>
                      <Button
                        className={`chat-react-btn ${
                          showEmoji === chat?.id ? "active" : ""
                        }`}
                        onClick={() => setShowEmoji(chat?.id)}
                      >
                        <EmojiIcon />
                      </Button>
                      <div
                        className={`chat-reaction-emoji ${
                          showEmoji === chat?.id ? "show" : ""
                        }`}
                      >
                        <ChatEmoji
                          name="reaction"
                          handleReaction={handleReaction}
                          setShowEmoji={setShowEmoji}
                          chatId={chat?.id}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
            </React.Fragment>
          ))}
          <span id="blankChat" ref={scrollableRef} />
        </div>
      </div>
      <div className="tab-card-bottom waiting-tab-card-bottom">
        <Form
          className="Chat-input-wrapper waiting-chat-input-wrapper"
          onSubmit={handleSubmit}
        >
          <Form.Control
            type="text"
            placeholder="Type to chat"
            id="chatMessage"
            maxLength={maxLength}
            onChange={(e) => handleChangeMessage(e, "chat")}
            value={message}
            autoComplete="off"
          />
          <div className="cam-emoji-wrapper">
            <div ref={showCamOption}>
              <span
                className="emoji-input-btn cam-input-btn"
                onClick={() => setShowCam(!showCam)}
                role="presentation"
              >
                <ChatCamIcon />
              </span>
              <div
                className={`chat-input-emoji chat-input-cam ${
                  showCam ? "show" : ""
                }`}
              >
                <div className="cam-input-wrapper">
                  <span onClick={() => handleCamModal()} role="presentation">
                    Take photo
                  </span>
                  <span onClick={() => handleUploadModal()} role="presentation">
                    Upload photo
                  </span>
                </div>
              </div>
            </div>
            <div>
              <span
                className="emoji-input-btn"
                onClick={() => setShowEmoji("chat")}
                role="presentation"
              >
                <ChatBtnIcon />
              </span>
            </div>
          </div>
          <div
            className={`chat-input-emoji ${showEmoji === "chat" ? "show" : ""}`}
          >
            <ChatEmoji
              name="chat"
              selectEmoji={handleChangeMessage}
              setShowEmoji={setShowEmoji}
              handleReaction={handleChangeMessage}
            />
          </div>

          <Button className="send-icon-wrapper" type="submit">
            <SendIconV2 />
          </Button>
        </Form>
      </div>
      {showProfile && (
        <div className="profile-viewer chat-profile-view">
          <span
            className="close-profile"
            onClick={handleClose}
            role="presentation"
          >
            <img src={cross} alt="close icon" />
          </span>
          <ProfileModal user={chatUserId} />
        </div>
      )}
      <CamModal
        showCamModal={showCamModal}
        handleCamClose={handleCamClose}
        takePicture={takePicture}
        clearPhoto={clearPhoto}
        file={file}
        handleSendMessageWithImage={handleSendMessageWithImage}
        preview={preview}
        allCameraOptions={allCameraOptions}
        handleCamerOptions={handleCamerOptions}
        handleChangeMessage={handleChangeMessage}
        handleImageChange={handleImageChange}
        handleCaption={handleCaption}
        sendImgLoader={sendImgLoader}
        selectedCamera={selectedCamera}
        cameraError={cameraError}
      />
      <UploadImageModal
        showUploadModal={showUploadModal}
        setShowUploadModal={setShowUploadModal}
        takePicture={takePicture}
        clearPhoto={clearPhoto}
        file={file}
        handleSendMessageWithImage={handleSendMessageWithImage}
        preview={preview}
        allCameraOptions={allCameraOptions}
        handleCamerOptions={handleCamerOptions}
        handleChangeMessage={handleChangeMessage}
        handleImageChange={handleImageChange}
        handleCaption={handleCaption}
        sendImgLoader={sendImgLoader}
        uploadError={uploadError}
        setUploadError={setUploadError}
      />
      <ViewImageModal
        chatImageUrl={chatImageUrl}
        viewImage={viewImage}
        handleViewClose={handleViewClose}
      />
    </div>
  );
}

Chat.defaultProps = {
  isExpanded: false,
  isInGameChat: false,
};

Chat.propTypes = {
  chats: PropsTypes.array.isRequired,
  isExpanded: PropsTypes.bool,
  isInGameChat: PropsTypes.bool,
};

export default Chat;
